<template>
  <Page title="Source Data">
    <SourceDataTable sync-url />
  </Page>
</template>

<script>
import SourceDataTable from '../components/SourceDataTable.vue'

export default {
  components: {
    SourceDataTable
  }
}
</script>
